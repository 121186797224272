import React, { useState, useEffect } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageList, MessageInput, Thread, Window } from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';


const apiKey = '8k6g837cvx3q';
const userName = 'cold3';

const userId = 'test_user';
const userToken = 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoidGVzdF91c2VyIiwiaWF0IjoxNzM5NDcwNTU3LCJpc3MiOiJTdHJlYW0gQ2hhdCBKYXZhIFNESyIsInN1YiI6IlN0cmVhbSBDaGF0IEphdmEgU0RLIn0.oCpL1Dqpd2m-TTVO9UcW5jeIJ5l-KThsAerTsUstg8U';


const user = {
  id: userId,
  image: `https://getstream.io/random_png/?name=${userName}`,
};

const Test2 = () => {
  const [channel, setChannel] = useState(null);
  const client = StreamChat.getInstance(apiKey);

  useEffect(() => {
    const connectUser = async () => {
      await client.connectUser(user, userToken);

      const channel = client.channel('messaging', 'custom_channel_id');

      setChannel(channel);
    };

    connectUser();

    return () => {
      client.disconnectUser();
    };
  }, [client]);

//   if (!channel) return <div>Loading...</div>;

  return (
    <Chat client={client} theme="messaging light">
      <Channel channel={channel}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default Test2;