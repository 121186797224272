import React, { useState, useEffect } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageList, MessageInput, Thread, Window } from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';


const apiKey = '8k6g837cvx3q';
const userName = 'cold3';
const userId = 'john_userid';
const userToken = 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiam9obl91c2VyaWQiLCJpYXQiOjE3Mzk0NjQwNDEsImlzcyI6IlN0cmVhbSBDaGF0IEphdmEgU0RLIiwic3ViIjoiU3RyZWFtIENoYXQgSmF2YSBTREsifQ.ft_Tyz-QP6TY9W99jvBLk_eAV1pdi_ZYA0c-IPH7h6k';

// const userId = 'test_user';
// const userToken = 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoidGVzdF91c2VyIiwiaWF0IjoxNzM5NDY5NjAyLCJpc3MiOiJTdHJlYW0gQ2hhdCBKYXZhIFNESyIsInN1YiI6IlN0cmVhbSBDaGF0IEphdmEgU0RLIn0.vS3Z-iD8Ou1ZdYx67Cp0axQY1anKOr9auuM_WGpu18c';


const user = {
  id: userId,
  image: `https://getstream.io/random_png/?name=${userName}`,
};

const Test = () => {
  const [channel, setChannel] = useState(null);
  const client = StreamChat.getInstance(apiKey);

  useEffect(() => {
    const connectUser = async () => {
      await client.connectUser(user, userToken);

      const channel = client.channel('messaging', 'custom_channel_id', {
        image: 'https://getstream.io/random_png/?name=react',
        name: 'Talk about React',
        members: ['test_user', 'john_userid'],
      });

      setChannel(channel);
    };

    connectUser();

    return () => {
      client.disconnectUser();
    };
  }, [client]);

//   if (!channel) return <div>Loading...</div>;

  return (
    <Chat client={client} theme="messaging light">
      <Channel channel={channel}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default Test;